@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

#pushSection .chakra-select__icon-wrapper {
  display: none !important;
}

#pushSection select option {
  padding: 5px 0px;
}

.paginate_section ul,
li {
  list-style-type: none;
}

.paginate_section ul {
  width: 500px;
  display: flex !important;
  justify-content: space-between;
}

.paginate_section li {
  display: inline-block;
}

.chakra-progress {
  width: 100%!important;
  margin: auto;

}

.chakra-progress svg {
  margin: auto!important;
}